import {API_CLIENT} from "@/services/axios";

export default {
    getVehicles() {
        return API_CLIENT.get('/admin-vehicles/');
    },
    getVehiclesByUserId(userId) {
        return API_CLIENT.get(`/admin-vehicles/user-vehicles/${userId}`);
    },
    getVehicle(id) {
        return API_CLIENT.get(`/admin-vehicles/${id}`);
    },
    createVehicle(vehicle) {
        return API_CLIENT.post('/admin-vehicles/', vehicle);
    },
    updateVehicle(id, vehicle) {
        return API_CLIENT.put(`/admin-vehicles/${id}`, vehicle);
    },
    deleteVehicle(id) {
        return API_CLIENT.delete(`/admin-vehicles/${id}`);
    },
    getStatistics() {
        return API_CLIENT.get('/admin-vehicles/stats/statistics');
    },


    async uploadThumbnail(vehicleId, file) {
        const formData = new FormData();
        formData.append('file', file);

        await API_CLIENT.post(`/admin-vehicles/${vehicleId}/upload-thumbnail`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });
    },

    async uploadGallery(vehicleId, files) {
        const formData = new FormData();
        for (const file of files) {
            formData.append('files', file);
        }

        await API_CLIENT.post(`/admin-vehicles/${vehicleId}/upload-gallery`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });
    },

    async uploadAfterDelivery(vehicleId, files) {
        const formData = new FormData();
        for (const file of files) {
            formData.append('files', file);
        }

        await API_CLIENT.post(`/admin-vehicles/${vehicleId}/upload-after-delivery`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });
    },
    async searchVin(query) {
        return API_CLIENT.get(`/admin-vehicles/sort/filter/${query}`);
    },

    deleteGalleryImage(vehicleId, imagePath) {
        return API_CLIENT.delete(`/admin-vehicles/${vehicleId}/gallery-image`, {
            data: { imagePath }
        });
    },

    deleteDeliveryImage(vehicleId, imagePath) {
        return API_CLIENT.delete(`/admin-vehicles/${vehicleId}/delivery-image`, {
            data: { imagePath }
        });
    }
};
