import {API_CLIENT} from "@/services/axios";

export default {
    createUser(user) {
        return API_CLIENT.post('/admin-users/register', user);
    },

    // Fetch all users
    getUsers() {
        return API_CLIENT.get('/admin-users/');
    },

    // Fetch a single user by ID
    getUser(id) {
        return API_CLIENT.get(`/admin-users/${id}`);
    },

    // Fetch a single user by ID
    deleteUser(id) {
        return API_CLIENT.delete(`/admin-users/${id}`);
    },

    // Update an existing user by ID
    updateUser(id, user) {
        return API_CLIENT.put(`/admin-users/${id}`, user);
    },
    searchUsers(search) {
        return API_CLIENT.get('/admin-users/sort/search', { params: { search: search } });
    },
    uploadUserFile(userId, formData) {
        return API_CLIENT.post(`/admin-users/thumbnail/${userId}/upload`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });
    },
    uploadUserPassport(userId, formData) {
        return API_CLIENT.post(`/admin-users/passport/${userId}/upload`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });
    },
    getPicture(filename) {
        return API_CLIENT.get(`/admin-users/thumbnail/${filename}`);
    }
};
