import { createApp } from 'vue'
import App from './App.vue'
import {Router} from "@/router";
import 'vuetify/styles'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { createVuetify } from 'vuetify'
import '@mdi/font/css/materialdesignicons.css'


const vuetify = createVuetify({ components, directives })
createApp(App).use(Router).use(vuetify).mount('#app')
