<template>
  <v-container>
    <v-form ref="form">
      <v-text-field v-model="port.name" label="Port Name" required></v-text-field>
      <v-select
          v-model="port.countryId"
          :items="countries"
          item-text="name"
          item-value="id"
          item-title="name"
          label="Country"
          required
      ></v-select>

      <v-checkbox v-model="port.showOnHomePage" label="Show port on home screen"></v-checkbox>
      <v-checkbox v-model="port.isLoading" label="It's a loading port"></v-checkbox>
      <v-checkbox v-model="port.isDestination" label="It's a destination port"></v-checkbox>
    </v-form>
    <v-btn color="primary" @click="submitForm">Save</v-btn>
    <v-btn text @click="$router.push('/ports')">Cancel</v-btn>
  </v-container>
</template>

<script>
import portService from '@/services/portService';
import countryService from '@/services/countryService';

export default {
  data() {
    return {
      port: {
        name: '',
        countryId: null,
        showOnHomePage: true,
        isLoading: false,
        isDestination: false,
      },
      countries: [],
    };
  },
  created() {
    this.loadCountries();
    if (this.$route.params.id) {
      this.loadPort();
    }
  },
  methods: {
    async loadPort() {
      const response = await portService.getPort(this.$route.params.id);
      this.port = response.data;
      this.port.countryId = response.data.country.id;
    },
    async loadCountries() {
      const response = await countryService.getCountries();
      console.log(response.data)
      this.countries = response.data.sort();
    },
    async submitForm() {
      if (this.$route.params.id) {
        await portService.updatePort(this.$route.params.id, this.port);
      } else {
        await portService.createPort(this.port);
      }
      this.$router.push('/ports');
    },
  },
};
</script>
