import {API_CLIENT} from "@/services/axios";

export default {
    getStatuses() {
        return API_CLIENT.get("/statuses/");
    },
    getStatus(id) {
        return API_CLIENT.get(`/statuses/${id}`);
    },
    createStatus(status) {
        return API_CLIENT.post("/statuses/", status);
    },
    updateStatus(id, status) {
        return API_CLIENT.put(`/statuses/${id}`, status);
    },
    uploadIcon(id, formData) {
        return API_CLIENT.post(`/statuses/${id}/upload-icon`, formData, {
            headers: { "Content-Type": "multipart/form-data" },
        });
    },
    deleteStatus(id) {
        return API_CLIENT.delete(`/statuses/${id}`);
    },
};
