import {API_CLIENT} from "@/services/axios";

export default {
    login(username, password) {
        return API_CLIENT.post('/auth/login', {
            username,
            password
        });
    },
};
