import axios from "axios";

export const BACKEND_BASE_URL = process.env.NODE_ENV === 'production' ? 'https://webservice.aliqleemalshamalicars.com/api/' : 'http://localhost:3000/api/'

const apiClient = axios.create({
    baseURL: BACKEND_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

apiClient.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('access_token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
apiClient.interceptors.response.use((response) => {
    return response;
}, (error) => {
    if (error.response.status === 403 || error.response.status === 401) {
        location.href = '/login'
    }
})

export const API_CLIENT = apiClient;