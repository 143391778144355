<template>
  <v-container class="py-4">
    <v-card>
      <v-card-title>Upload PDF</v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <!-- File Input -->
          <v-file-input
              v-model="selectedFile"
              label="Select a PDF file"
              accept="application/pdf"
              :rules="[fileRequired]"
              outlined
              dense
          ></v-file-input>

          <!-- Upload Button -->
          <v-btn
              :disabled="!valid || !selectedFile"
              color="primary"
              class="mt-3"
              @click="uploadPdf"
          >
            Upload
          </v-btn>
        </v-form>

        <!-- Display Uploaded PDF -->
        <v-card v-if="pdfUrl" class="mt-5">
          <v-card-title>Uploaded PDF</v-card-title>
          <v-card-text>
            <iframe :src="pdfUrl" width="100%" height="500px"></iframe>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import PdfService from "@/services/pdfService";

export default {
  name: 'PdfUploadForm',
  data() {
    return {
      selectedFile: null, // Holds the selected file
      pdfUrl: '', // URL to display the uploaded PDF
      valid: false, // Form validation state
    };
  },
  mounted() {
    this.fetchLastUploadedPdf();
  },
  methods: {
    // Validation Rule
    fileRequired(value) {
      return !!value || 'File is required';
    },
    async fetchLastUploadedPdf() {
      try {
        const response = await PdfService.getLast();
        this.pdfUrl = response.data.filePath;
      } catch (error) {
        console.error('Error fetching last uploaded PDF:', error);
        this.pdfUrl = '';
      }
    },


    // Upload PDF File
    async uploadPdf() {
      if (!this.selectedFile) return;

      try {
        const file = await PdfService.upload(this.selectedFile)
        this.pdfUrl = file.data.filePath;
      } catch (error) {
        console.error('Error uploading PDF:', error);
        alert('Failed to upload PDF.');
      }
    },
  },
};
</script>

<style scoped>
/* Optional: Styling adjustments */
iframe {
  border: none;
}
</style>
