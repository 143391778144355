<template>
  <v-container>
    <v-row>
      <v-col cols="4">
        <v-card>
          <v-card-title>Total Vehicles</v-card-title>
          <v-card-text>{{ statistics.totalVehicles }}</v-card-text>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card>
          <v-card-title>Average Vehicle Year</v-card-title>
          <v-card-text>{{ statistics.averageYear }}</v-card-text>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card>
          <v-card-title>Total Towing Cost</v-card-title>
          <v-card-text>{{ statistics.towingCostTotal }}</v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import vehicleService from '@/services/vehicleService';

export default {
  data() {
    return {
      statistics: {
        totalVehicles: 0,
        averageYear: 0,
        towingCostTotal: 0,
      },
    };
  },
  created() {
    this.loadStatistics();
  },
  methods: {
    loadStatistics() {
      try {
        vehicleService.getStatistics().then((response) => {
          this.statistics = response.data;
        });
      } catch (e) {
        console.log(e)
      }
    },
  },
};
</script>
