<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-data-table
            :headers="headers"
            :items="statusList"
            item-value="id"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Vehicle Status</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="$router.push('/statuses/create')">Status Create</v-btn>
            </v-toolbar>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-btn icon @click="editStatus(item.id)">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn icon color="red" @click="deleteStatus(item.id)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import statusService from "@/services/statusService";

export default {
  data() {
    return {
      statusList: [],
      headers: [
        { text: "ID", value: "id" },
        { text: "Status", value: "status" },
        { text: "Vehicle ID", value: "vehicleId" },
        { text: "Timestamp", value: "timestamp" },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },
  created() {
    this.fetchStatuses();
  },
  methods: {
    async fetchStatuses() {
      try {
        const response = await statusService.getStatuses();
        this.statusList = response.data;
      } catch (error) {
        console.error("Error fetching statuses:", error);
      }
    },
    editStatus(id) {
      this.$router.push(`/statuses/${id}/edit`);
    },
    async deleteStatus(id) {
      try {
        await statusService.deleteStatus(id);
        await this.fetchStatuses();
      } catch (error) {
        console.error("Error deleting status:", error);
      }
    },
  },
};
</script>
