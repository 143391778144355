import {createRouter, createWebHistory} from 'vue-router';
import HomePage from "@/components/HomePage.vue";
import VehicleTable from "@/components/VehicleTable.vue";
import VehicleForm from "@/components/VehicleForm.vue";
import ListOfPort from "@/components/ListOfPort.vue";
import PortForm from "@/components/PortForm.vue";
import VehicleStatusTable from "@/components/VehicleStatusTable.vue";
import VehicleStatusForm from "@/components/VehicleStatusForm.vue";
import UserTable from "@/components/UserTable.vue";
import AddUser from "@/components/AddUser.vue";
import EditUser from "@/components/EditUser.vue";
import Login from "@/components/Login.vue";
import Dashboard from "@/default/Dashboard.vue";
import PDFofSales from "@/components/PDFofSales.vue";


const router = new createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            name: 'def',
            redirect: {
                name: "Dashboard"
            },
        },
        {
            path: '/dashboard',
            name: 'Dashboard',
            component: Dashboard,
            redirect: {
                name: "Home"
            },
            children: [
                {
                    path: '/home',
                    name: 'Home',
                    component: HomePage,
                },
                {
                    path: '/PDF_SALE',
                    name: 'PDF_SALE',
                    component: PDFofSales,
                },
                {
                    path: '/users/edit/:id',
                    name: 'EditUser',
                    component: () => import('@/components/EditUser.vue'),
                },
                {
                    path: '/users/profile/:id',
                    name: 'UserProfile',
                    component: () => import('@/components/UserProfile.vue'),
                },
                {
                    path: '/vehicles',
                    name: 'Vehicles',
                    component: VehicleTable,
                },
                {
                    path: '/vehicles/create',
                    name: 'CreateVehicle',
                    component: VehicleForm,
                },
                {
                    path: '/vehicles/edit/:id',
                    name: 'EditVehicle',
                    component: VehicleForm,
                },
                {
                    path: '/ports',
                    name: 'Ports',
                    component: ListOfPort,
                },
                {
                    path: '/ports/create',
                    name: 'CreatePorts',
                    component: PortForm,
                },
                {
                    path: '/ports/:id/edit',
                    name: 'UpdatePorts',
                    component: PortForm,
                },
                {
                    path: '/statuses',
                    name: 'Statuses',
                    component: VehicleStatusTable,
                },
                {
                    path: '/statuses/create',
                    name: 'StatusesCreate',
                    component: VehicleStatusForm,
                },
                {
                    path: '/statuses/:id/edit',
                    name: 'StatusesUpdate',
                    component: VehicleStatusForm,
                },
                {
                    path: '/users',
                    name: 'Users',
                    component: UserTable,
                },
                {
                    path: '/users/create',
                    name: 'UsersCreate',
                    component: AddUser,
                },
                {
                    path: '/users/:id/edit',
                    name: 'UsersUpdate',
                    component: EditUser,
                },
            ]
        },
        {
            path: '/login',
            name: 'Login',
            component: Login,
        },
    ],
});


router.beforeEach((to, from, next) => {
    const token = localStorage.getItem('access_token');
    if (!token && to.name !== 'Login') {
        next({name: 'Login'});
    } else {
        next();
    }
});

export const Router = router;
