<template>
      <v-navigation-drawer theme="dark" permanent>
        <v-list nav>
          <v-list-item v-for="(item, index) of menuItems" :key="index" exact :prepend-icon="item.icon" :title="item.title" :to="item.to"></v-list-item>
        </v-list>
      </v-navigation-drawer>

      <v-app-bar>
        <v-app-bar-title style="max-width: 90px">Iqleem</v-app-bar-title>
          <v-autocomplete
              v-model="vin"
              variant="outlined"
              style="max-width: 300px;margin-top: 23px;"
              :items="suggestions"
              label="Search by VIN"
              outlined
              item-title="vin"
              density="compact"
              item-value="id"
              return-object
              :loading="loadingVin"
              @update:modelValue="openVehicle"
              @update:search="fetchSuggestions"
              @change="fetchSuggestions"
          ></v-autocomplete>
        <template v-slot:append>
          <v-btn @click="logout" icon="mdi-logout"></v-btn>
        </template>
      </v-app-bar>

      <v-main>
        <v-container>
          <router-view />
        </v-container>
      </v-main>
</template>

<script>

import VehicleService from "@/services/vehicleService";

export default {
  name: 'App',
  data() {
    return {
      loadingVin: false,
      suggestions: [],
      vin: null,
      menuItems: [
        {
          title: 'Home',
          to: '/dashboard',
          icon: 'mdi-view-list'
        },
        {
          title: 'List of vehicles',
          to: '/vehicles',
          icon: 'mdi-view-list'
        },
        {
          title: 'List of Ports',
          to: '/Ports',
          icon: 'mdi-view-list'
        },
        {
          title: 'Status List',
          to: '/Statuses',
          icon: 'mdi-view-list'
        },
        {
          title: 'List of Users',
          to: '/Users',
          icon: 'mdi-view-list'
        },
        {
          title: 'Pdf of sales',
          to: '/PDF_SALE',
          icon: 'mdi-view-list'
        }
      ],
    };
  },
  methods: {
    logout() {
      localStorage.removeItem('access_token')
      location.reload()
    },
    openVehicle($event) {
      this.$router.push(`/vehicles/edit/${$event.id}`)
      this.vin = '';
    },
    async fetchSuggestions(query) {
      if (!query) return;
      this.loadingVin = true;
      try {
        const response = await VehicleService.searchVin(query);
        this.suggestions = response.data;
      } catch (error) {
        console.error("Error fetching suggestions:", error);
      } finally {
        this.loadingVin = false;
      }
    },
  },
}
</script>
