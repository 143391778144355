<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" md="8">
        <v-card>
          <v-card-title>
            <span class="text-h6">{{ isEditMode ? 'Edit Status' : 'Create Status' }}</span>
          </v-card-title>

          <v-card-text>
            <v-form ref="form">
              <v-text-field
                  v-model="statusForm.status"
                  label="Status"
                  required
              ></v-text-field>

              <!-- File input for SVG upload -->
              <v-file-input
                  label="Upload Icon (SVG)"
                  accept=".svg"
                  @change="uploadIcon"
                  :rules="[file => (file && file.type === 'image/svg+xml') || 'Only SVG files are allowed']"
              ></v-file-input>

              <v-checkbox label="Show on home page" v-model="statusForm.showOnHomePage" />
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-btn color="primary" @click="submitForm">
              {{ isEditMode ? 'Update' : 'Create' }}
            </v-btn>
            <v-btn text @click="$router.push('/statuses')">Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import statusService from "@/services/statusService";

export default {
  data() {
    return {
      statusForm: {
        id: null,
        status: "",
        icon: null,
        showOnHomePage: false,
      },
      isEditMode: false,
    };
  },
  created() {
    if (this.$route.params.id) {
      this.loadStatus();
      this.isEditMode = true;
    }
  },
  methods: {
    async loadStatus() {
      try {
        const response = await statusService.getStatus(this.$route.params.id);
        this.statusForm = response.data;
      } catch (error) {
        console.error("Error loading status:", error);
      }
    },
    async submitForm() {
      try {
        if (this.isEditMode) {
          await statusService.updateStatus(this.statusForm.id, this.statusForm);
        } else {
          await statusService.createStatus(this.statusForm);
        }
        this.$router.push("/statuses");
      } catch (error) {
        console.error("Error submitting status form:", error);
      }
    },
    async uploadIcon(event) {
      const file = event.target.files[0];
      if (file) {
        try {
          const formData = new FormData();
          formData.append("file", file);

          const response = await statusService.uploadIcon(this.statusForm.id, formData);
          this.statusForm.icon = response.data.filePath; // Store the file path returned
        } catch (error) {
          console.error("Error uploading icon:", error);
        }
      }
    },
  },
};
</script>
