<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" md="6">
        <v-card>
          <v-card-title>
            <span class="text-h6">Create User</span>
          </v-card-title>
          <v-card-text>
            <v-form ref="form">
              <!-- Username -->
              <v-text-field
                  v-model="user.username"
                  label="Username"
                  required
              ></v-text-field>

              <!-- Email -->
              <v-text-field
                  v-model="user.name"
                  label="Name"
                  type="text"
                  required
              ></v-text-field>

              <!-- Password -->
              <v-text-field
                  v-model="user.password"
                  label="Password"
                  type="password"
                  required
              ></v-text-field>

              <!-- Password -->
              <v-text-field
                  v-model="user.phoneNumber"
                  label="Phone number"
                  required
              ></v-text-field>

              <!-- Password -->
              <v-text-field
                  v-model="user.address"
                  label="Address"
                  required
              ></v-text-field>

              <!-- Role -->
              <v-select
                  v-model="user.role"
                  :items="roles"
                  label="Role"
                  required
              ></v-select>

              <v-file-input
                  v-model="passportImage"
                  label="Upload Passport Image"
                  accept="image/*"
              ></v-file-input>
              <v-file-input
                  v-model="profileThumbnail"
                  label="Upload Profile Thumbnail"
                  accept="image/*"
              ></v-file-input>

            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-btn color="primary" @click="submitForm">Create User</v-btn>
            <v-btn text @click="$router.push('/users')">Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import userService from '@/services/userService'; // Assuming this file handles API calls

export default {
  data() {
    return {
      user: {
        username: '',
        email: '',
        name: '',
        phoneNumber: '',
        address: '',
        role: 'user',
      },
      roles: ['user', 'admin'],
      passportImage: null,
      profileThumbnail: null,
    };
  },
  methods: {
    async submitForm() {
      try {
        // Call the backend to create the user
        const res = await userService.createUser(this.user);
        this.user = res.data;
        if (this.passportImage) {
          await this.uploadPassportImage(this.passportImage)
        }
        if (this.profileThumbnail) {
          await this.uploadThumbnail(this.profileThumbnail)
        }
        this.$router.push('/users');  // Redirect to the users list

      } catch (error) {
        console.error('Error creating user:', error);
      }
    },
    async uploadThumbnail(file) {
      const formData = new FormData();
      formData.append('file', file);
      try {
        const response = await userService.uploadUserFile(this.user.id, formData);
        this.user.profileThumbnail = response.data.profileThumbnail;
      } catch (error) {
        console.error('Error uploading thumbnail:', error);
      }
    },
    async uploadPassportImage(file) {
      const formData = new FormData();
      formData.append('file', file);
      try {
        const response = await userService.uploadUserPassport(this.user.id, formData);
        this.user.passportImage = response.data.passportImage;
      } catch (error) {
        console.error('Error uploading passport image:', error);
      }
    },
  },
};
</script>
