<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="12">
        <v-data-table
            :headers="headers"
            :items="users"
            item-key="id"
            :loading="loading"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Users List</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="$router.push('/users/create')">Add User</v-btn>
            </v-toolbar>
          </template>
          <template v-slot:item.role="{ item }">
            <v-chip :color="item.role === 'admin' ? 'red' : 'green'" dark>
              {{ item.role }}
            </v-chip>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn icon @click="viewProfile(item.id)">
              <v-icon>mdi-account</v-icon>
            </v-btn>
            <v-btn icon color="primary" @click="editUser(item.id)">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn icon color="red" @click="deleteUser(item.id)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import userService from '@/services/userService';

export default {
  data() {
    return {
      users: [],
      loading: true,
      headers: [
        { text: 'Username', align: 'start', key: 'username', sortable: false },
        { text: 'Name', value: 'name', sortable: false },
        { text: 'Role', value: 'role' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
    };
  },
  created() {
    this.fetchUsers();
  },
  methods: {
    async fetchUsers() {
      try {
        const response = await userService.getUsers();
        this.users = response.data;
      } catch (error) {
        console.error('Error fetching users:', error);
      } finally {
        this.loading = false;
      }
    },
    async deleteUser(id) {
      try {
        await userService.deleteUser(id);
        this.users = this.users.filter((user) => user.id !== id);
      } catch (error) {
        console.error('Error deleting user:', error);
      }
    },
    editUser(id) {
      this.$router.push(`/users/edit/${id}`);
    },
    viewProfile(id) {
      this.$router.push(`/users/profile/${id}`);
    },
  },
};
</script>
