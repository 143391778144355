import {API_CLIENT} from "@/services/axios";

export default {
    getPorts() {
        return API_CLIENT.get('/ports/');
    },
    getDestinationPorts() {
        return API_CLIENT.get('/ports/sort/destination');
    },
    getLoadingPorts() {
        return API_CLIENT.get('/ports/sort/loading');
    },
    getPort(id) {
        return API_CLIENT.get(`/ports/${id}`);
    },
    createPort(data) {
        return API_CLIENT.post('/ports/',data);
    },
    updatePort(id, data) {
        return API_CLIENT.patch(`/ports/${id}`, data);
    },
    deletePort(id) {
        return API_CLIENT.delete(`/ports/${id}`);
    },
};
