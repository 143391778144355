<template>
  <v-container>
    <v-data-table
        :headers="headers"
        :items="vehicles"
        item-value="id"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Vehicles</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="$router.push('/vehicles/create')">Add Vehicle</v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small @click="$router.push(`/vehicles/edit/${item.id}`)">mdi-pencil</v-icon>
        <v-icon small color="red" @click="deleteVehicle(item.id)">mdi-delete</v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import vehicleService from '@/services/vehicleService';

export default {
  name: 'Vehicles',
  props: {
    userId: {
      default: null,
      type: Number
    }
  },
  data() {
    return {
      vehicles: [],
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Title Status', value: 'titleStatus' },
        { text: 'Year', value: 'year' },
        { text: 'Make', value: 'make' },
        { text: 'Model', value: 'model' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
    };
  },
  created() {
    if (this.userId) {
      this.getUserVehicles(this.userId);
    } else {
      this.loadVehicles();
    }
  },
  methods: {
    async loadVehicles() {
      try {
        const response = await vehicleService.getVehicles();
        this.vehicles = response.data;
      } catch (e) {
        console.log(e)
      }
    },
    async getUserVehicles(userId) {
      const data = await vehicleService.getVehiclesByUserId(userId);
      this.vehicles = data.data
    },
    deleteVehicle(id) {
      if (confirm('Are you sure you want to delete this vehicle?')) {
        vehicleService.deleteVehicle(id).then(() => {
          if (this.userId) {
            this.getUserVehicles(this.userId);
          } else {
            this.loadVehicles();
          }
        });
      }
    },
  },
};
</script>
