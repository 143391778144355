<template>
  <v-container>
    <v-data-table :items="ports" :headers="headers" item-value="id">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Ports</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="$router.push('/ports/create')">Add Port</v-btn>
        </v-toolbar>
      </template>
      <template #item.actions="{ item }">
        <v-btn icon @click="editPort(item.id)">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn icon color="red" @click="deletePort(item.id)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import portService from '@/services/portService';

export default {
  data() {
    return {
      ports: [],
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Name', value: 'name' },
        { text: 'Country', value: 'country.name' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
    };
  },
  created() {
    this.loadPorts();
  },
  methods: {
    async loadPorts() {
      const response = await portService.getPorts();
      this.ports = response.data;
    },
    editPort(id) {
      this.$router.push(`/ports/${id}/edit`);
    },
    async deletePort(id) {
      await portService.deletePort(id);
      await this.loadPorts();
    },
  },
};
</script>
