<template>
  <v-responsive class="rounded">
    <v-app>
      <router-view />
    </v-app>
  </v-responsive>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {
      menuItems: [
        {
          title: 'List of vehicles',
          to: 'vehicles',
          icon: 'mdi-view-list'
        },
        {
          title: 'List of Ports',
          to: 'Ports',
          icon: 'mdi-view-list'
        },
        {
          title: 'Status List',
          to: 'Statuses',
          icon: 'mdi-view-list'
        },
        {
          title: 'List of Users',
          to: 'Users',
          icon: 'mdi-view-list'
        }
      ],
    };
  },
}
</script>
