import {API_CLIENT} from "@/services/axios";

export default {
    upload(selectedFile) {
        if (!selectedFile) return;
        console.log(selectedFile)
        const formData = new FormData();
        formData.append('file', selectedFile);
        return API_CLIENT.post('/pdf/upload', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });
    },

    getLast() {
        return API_CLIENT.get('/pdf/last');
    }
};
