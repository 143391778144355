<template>
  <v-container class="d-flex justify-center align-center" style="height: 100vh;">
    <v-card elevation="10" max-width="400">
      <v-card-title class="text-center">
        <span class="text-h5">Admin Login</span>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-text-field
              v-model="username"
              label="username"
              :rules="[rules.required]"
              outlined
              clearable
          ></v-text-field>
          <v-text-field
              v-model="password"
              label="Password"
              type="password"
              :rules="[rules.required]"
              outlined
              clearable
          ></v-text-field>
          <v-alert v-if="error" type="error" dismissible>{{ error }}</v-alert>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn block color="primary" :disabled="!valid" @click="login">
          Login
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import authService from "@/services/authService";

export default {
  data() {
    return {
      username: '',
      password: '',
      valid: false,
      error: null,
      rules: {
        required: (value) => !!value || 'This field is required.'
      },
    };
  },
  methods: {
    async login() {
      try {
        const response = await authService.login(
           this.username,
           this.password,
        );
        // Save token and navigate to the dashboard
        localStorage.setItem('access_token', response.data.access_token);
        this.$router.push('/dashboard');
      } catch (err) {
        this.error =
            err.response?.data?.message || 'An error occurred. Please try again.';
      }
    },
  },
};
</script>

<style scoped>
.v-card {
  width: 100%;
  max-width: 400px;
}
</style>
